import { Component, OnInit, Input } from '@angular/core';
import { BlogService } from '../blog.service';
import { BlogArticle } from '../blog-article.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss']
})
export class BlogArticleComponent implements OnInit {

  @Input()
  selfComponent: boolean;

  constructor(private blogService: BlogService, private acitvatedRoute: ActivatedRoute) {

    this.article = new BlogArticle();
    this.selfComponent = true;
  }

  @Input() article: BlogArticle;


  ngOnInit() {


    this.acitvatedRoute.params.subscribe(params => {
      let id = params['seo_url'] as string;

      if (id.length > 0) {

        this.blogService.getArticle(id).subscribe(res =>

          this.article = this.blogService.mapArticle(res.body))


      };

    });

  }

}
