import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referencies',
  templateUrl: './referencies.component.html',
  styleUrls: ['./referencies.component.scss']
})
export class ReferenciesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
