import { Component, OnInit, Input } from '@angular/core';



export class ItemDef {
  path: string;
  isVideo: boolean;
}

@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrls: ['./galery.component.scss']
})
export class GaleryComponent implements OnInit {

  @Input()
  selfComponent: boolean;

  images: ItemDef[];

  imagesSource: string[];


  constructor() {
    this.selfComponent = true;
    this.images = [];
    this.imagesSource = [];
    this.imagesSource.push("/../assets/img/galeria/stikanie.mp4")
    //this.imagesSource.push("/../assets/img/galeria/deti.jpeg")
    this.imagesSource.push("/../assets/img/galeria/telerano.jpeg")
    this.imagesSource.push("/../assets/img/galeria/poskoky.mp4")
    this.imagesSource.push("/../assets/img/galeria/svadba.jpeg")
    
    this.imagesSource.push("/../assets/img/galeria/stan.jpeg")
    //this.imagesSource.push("/../assets/img/galeria/stanky.jpeg")
  //  this.imagesSource.push("/../assets/img/galeria/plieskaniebicom.jpeg")
    //this.imagesSource.push("/../assets/img/galeria/animatori.jpeg")




  }

  ngOnInit() {

    let ret: string[] = [];
    if (this.selfComponent) {


      ret = this.imagesSource;
    }
    else {
      ret = this.imagesSource.slice(0, 8);
    }

    for (let x of ret) {
      let n = new ItemDef();
      n.path = x;
      if (x.endsWith(".mp4")) {
        n.isVideo = true;
      }
      else {
        n.isVideo = false;
      }
      this.images.push(n);

    }

    console.log(this.images);
  }

}
