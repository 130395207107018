import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
  // isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
  //   const isSubmitted = form && form.submitted;
  //   return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  // }
}


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);

  // nameFormControl = new FormControl('', [
  //   Validators.required
  // ]);

  matcher = new MyErrorStateMatcher();


  contactForm: FormGroup;
  get f() { return this.contactForm.controls; }

  constructor(private fb: FormBuilder,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private translate:TranslateService
  ) { }

  ngOnInit() {

    this.contactForm = this.fb.group({
      nameFormControl: ['', [Validators.required]],
      emailFormControl: ['', [Validators.required, Validators.email]],
      textFormControl: ['', [Validators.required]]
    });

  }



  submitForm() {

    if (this.contactForm.invalid) {
      return;
    }

    this.api.sendMail(this.f.emailFormControl.value, this.f.nameFormControl.value, this.f.textFormControl.value);

 
    const tran = this.translate.instant('Contact.Sended');
    
    this.snackBar.open(tran,"Email", {
      duration: 3000,
      verticalPosition: 'top'
    });

  }



}
