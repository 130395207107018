import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infografic',
  templateUrl: './infografic.component.html',
  styleUrls: ['./infografic.component.scss']
})
export class InfograficComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
