import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';



import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


export class MailInfo
{
  name:string;
  body:string;
  email:string;

}


@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }




  sendMail( email:string, name:string,  bodytext:string) {

    let obj = new MailInfo();
    obj.name = name;
    obj.body = bodytext;
    obj.email = email;
    const api = "/api/mail/sendMail.php";


      this.post<number>(api,obj).subscribe(res=> console.log("Done",res.body));
  }


  
  public get<T>(url: string, p: HttpParams = null): Observable<HttpResponse<T>> {
    return this.http.get<T>(url, { observe: 'response', params: p });
  }

  // public put<T>(url: string, object: T): Observable<HttpResponse<T>> {
  //     return this.http.put<T>(url, object)
  //         .catch(error => this.handleError(error));
  // }

  public post<T>(url: string, object: any): Observable<HttpResponse<T>> {



    return this.http.post<T>(url, object, { observe: 'response' });

  }

  // private handleError(err: HttpErrorResponse) {
  //   let rethrow: boolean = true;


  //   console.log(err.message);
  //   console.log(err.statusText);

  //   return Observable.throw(err);
  // }
}
