import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ApiService } from './api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule } from '@angular/common/http';
import { ContactComponent } from './contact/contact.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatInputModule, MatFormFieldModule, MatCardModule, MatIconModule, MatButtonModule, MatTableModule, MatMenuModule, MatSortModule, MatDialogModule, MatChipsModule, MatSelectModule, MatSlideToggleModule, MatRadioModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ReferenciesComponent } from './referencies/referencies.component';
import { InfograficComponent } from './infografic/infografic.component';
import { GaleryComponent } from './galery/galery.component';
import { BlogArticleComponent } from './blog/blog-article/blog-article.component';
import { BlogArticlesListComponent } from './blog/blog-articles-list/blog-articles-list.component';
import {SlideshowModule} from 'ng-simple-slideshow';
import { PublicComponent } from './public/public.component';


@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    NavigationComponent,
    FooterComponent,
    HomeComponent,
    IntroductionComponent,
    AboutUsComponent,
    OurTeamComponent,
    ReferenciesComponent,
    InfograficComponent,
    TruncatePipe,
    GaleryComponent,
    BlogArticleComponent,
    BlogArticlesListComponent,
    PublicComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    SlideshowModule,
    ReactiveFormsModule,
MatSnackBarModule,
    TranslateModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatChipsModule,
    MatSelectModule,
    FlexLayoutModule,
    MatCardModule,
    HttpClientModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  exports:[
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatChipsModule,
    MatSelectModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatCardModule,
    MatRadioModule,
    MatProgressSpinnerModule,
  
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
