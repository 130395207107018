import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        'height': '100px',
         'visibility': 'visible',

      })),
      state('closed', style({
        'height': '0px',
        'visibility': 'hidden',
    
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
    // animation triggers go here
  ]
})
export class OurTeamComponent implements OnInit {


  aditionalInfo:boolean;
  lastInfo:number;
  aditionalInfoText:string;

  constructor(private translate:TranslateService ) {

    this.aditionalInfoText = "";
   }

  ngOnInit() {
  }



  openDesc(defNumber:number)
  {
    console.log(defNumber);
    if(defNumber == this.lastInfo)
    {

      this.aditionalInfo = false;
      defNumber = 0;
      this.aditionalInfoText = "";
    }
    else if(defNumber > 0)
    {
      this.aditionalInfo = true;
      let info = `Project.Desc${defNumber}`;

      setTimeout(() => {
        this.aditionalInfoText =  this.translate.instant(info);
      },
        600);
    }





  this.lastInfo = defNumber;

  }

}
