import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
//import { HttpProvider } from '../httpProvider.service';
import { BlogArticle, eBlogType } from './blog-article.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';


declare var require: any;


@Injectable({
  providedIn: 'root'
})
export class BlogService {

  articleList: BlogArticle[];


  //private controllerUrl = environment.apiUrl + "blog/";
  constructor( private httpClient:HttpClient) {

    this.articleList = [];

 

  }


  getContent( p: HttpParams = null):  Observable<HttpResponse<BlogArticle[]>> {
    const getdata =environment.api+ "blog/getContent.php";
    return this.httpClient.get<BlogArticle[]>(getdata, { observe: 'response' , params: p } );

  }


  getArticle(id: string):Observable<HttpResponse< BlogArticle>> {
    const getdata =environment.api+ "blog/getArticle.php?id=" + id;

    return this.httpClient.get<BlogArticle>(getdata, { observe: 'response' } );
  }


  mapArticle(art:BlogArticle) :BlogArticle
  {

    const Entities = require('html-entities').AllHtmlEntities;
 
    const entities = new Entities();

    art.text = entities.decode(art.text);
    return art;
  }



}
