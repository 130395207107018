import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(private animateScrollService: NgAnimateScrollService, private router: Router) { }

  ngOnInit() {
  }

  scrollTo(id: string) {



    var curUrl = this.router.url;


    var targetUrl = `/${id}`;

    console.log(id, "scrool to");
    if (curUrl == `/home`) {
      if (id == "home") {
        id="introduction";
      }

      let element = document.getElementById(id);
      if (element != undefined) {
        this.animateScrollService.scrollToElement(id, 750);
      }
      else {
        this.router.navigate([targetUrl]);
      }
    }
    else {
      let element = document.getElementById(id);

      if (element == undefined) {
        this.router.navigate([targetUrl]);
      }

    }
    this.animateScrollService.scrollToElement(id, 750);

  }



}
