import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GaleryComponent } from './galery/galery.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { ReferenciesComponent } from './referencies/referencies.component';
import { ContactComponent } from './contact/contact.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { BlogArticlesListComponent } from './blog/blog-articles-list/blog-articles-list.component';
import { BlogArticle } from './blog/blog-article.model';
import { BlogArticleComponent } from './blog/blog-article/blog-article.component';



const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'galery', component: GaleryComponent
  },
  {
    path: 'services', component: IntroductionComponent
  },
  {
    path: 'our-team', component: OurTeamComponent
  },
  {
    path: 'referencies', component: ReferenciesComponent
  },
  {
    path: 'clanky/:seo_url', component: BlogArticlesListComponent
  },
  {
    path: 'recenzie/:seo_url', component: BlogArticleComponent
  },
  { path: '**', component: HomeComponent 
},

];



@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false} // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
