import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { NgImageSliderComponent } from 'ng-image-slider';
import { IImage } from 'ng-simple-slideshow';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {


  @Input()
  selfComponent: boolean;
  @ViewChild('nav', { static: false }) slider: NgImageSliderComponent;




  imageObject: (string | IImage)[] = [

  {
    url: 'assets/img/galeria/home.jpg',
    href: 'http://google.sk',
    caption: 'Svadba'
  },
  {
    url: 'assets/img/galeria/svadba.jpeg',
    href: 'http://google.sk',
    caption: 'Svadba'
  },

  ];



  imageSize: object = { width: 480, height: 360 };

  constructor(private api: ApiService) {
    this.selfComponent = true;

  }


  imageClick() {
    console.log(this.slider, "xxxx");
  }

  ngOnInit() {
  }



}
