export class BlogArticle
{
    id:number;
    seo_url: string;
    name: string;
    text:string;
    created:Date;
    updated:Date;
    type:eBlogType;
    parent: number;
    sortOrder:number;
}



export enum eBlogType
{
    Information = 0,
    BlogArticle = 1,
    Recenzia = 2,
}