import { Component } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'event';


  constructor(private translate: TranslateService,
    titleService: Title, private meta: Meta)
{

  translate.setDefaultLang('sk');
  titleService.setTitle("Lubo Kubla");

  const keywords: MetaDefinition = {
    name: "keywords",
    content: "folklór, plánovanie akcií, eventy, detské eventy, event planning"
  }
  const description: MetaDefinition = {
    name: "description",
    content: "Naši tím tvoria profesionáli s dlhoročnými skúsenosťami! Spokojnosť našich klientov je na prvom mieste  a preto ponúkame program na mieru podľa ich požiadaviek, ale zároveň na základe našich skúseností. "   //todo
  }
  this.meta.addTags([keywords, description]);
  
}

onActivate(e) {

  window.scrollTo(0, 0);
}

switchLanguage(language: string) {
  this.translate.use(language);
}

}
